export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_TOP_PRODUCTS = "GET_TOP_PRODUCTS";
export const GET_TOP_PRODUCTS_FAILED = "GET_TOP_PRODUCTS_FAILED";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_TO_CART = "REMOVE_TO_CART";
export const RESET_CART = "RESET_CART";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CREATE_USER_ORDER = "CREATE_USER_ORDER";
export const USER_ORDER_DETAILS = "USER_ORDER_DETAILS";
export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";
export const GET_USER_ORDER_LIST = "GET_USER_ORDER_LIST";
export const CREATE_REVIEW = "CREATE_REVIEW";
export const CREATE_REVIEW_REQUEST = "CREATE_REVIEW_REQUEST";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const UPLOAD_PRODUCT_IMAGE = "UPLOAD_PRODUCT_IMAGE";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const ORDER_TO_DELIVERED = "ORDER_TO_DELIVERED";
